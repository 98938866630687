body{
	margin: 0;
	padding: 0;
	color: white;
        background: linear-gradient(90deg, rgb(53, 53, 53), rgb(22, 22, 22));
}
.black-bar{
	height: auto;
	width: 100%;
	background-color: black;
	padding: 5px;
	display: grid;
	place-items: center;
	position: fixed;
	bottom: 0;
}
.about-div{
	margin-top: 60px;
	margin-left: 10px;
}
.body-glass{
    background-color: rgba(85, 85, 85, 0.253);
    height: 75vh;
    width: 92%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    padding: 5px;
    border-radius: 5px;
}
CopyrightBar{
    position: fixed;
    bottom: 0;
}
