.Header{
    height: 100px;
    width: 100%;
    background: linear-gradient(90deg, rgb(51, 51, 51), rgb(22, 22, 22));
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin: 0;
    padding: 0;
}
nav{
    margin-top: 0;
    margin-left: 10px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-top: 5px;
}
nav h2{
    background-color: black;
    height: 30px;
    width: 37px;
    padding: 10px;
    border: 3px solid black;
    border-radius: 50%;
    font-size: 25px;
    letter-spacing: 1px;
}
nav h3{
    font-family:'Poppins', sans-serif;
    margin-left: 5px;
}
.title-glass{
    background-color: rgba(85, 85, 85, 0.589);
    height: 70px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px;
    padding: 5px;
    border-radius: 5px;
}
.options{
    width: 80%;
    margin-right: 5px;
    margin-left: 10px;
    display: flex;
    justify-content: space-around;
}
.options .Link{
    color: white;
    text-decoration: none;
    font-weight: 900;
    font-size: 19px;
}
@media(max-width:400px){
    nav h2{
        height: 27px;
        width: 33px;
    }
    .title-glass{
        height: 60px;
        width: 130px;
    }
    .options{
        width: 80%;
        flex-wrap: wrap;
    }
    .options .Link{
        margin-left: 7px;
        background-color: rgba(114, 114, 114, 0.596);
        padding: 0.2px;
        height: auto;
        width: 46%;
    }
}
